
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Bebas+Neue&display=swap');


.App {
  text-align: center;
}

.hero {
  background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4) ), url(./images/DSC05088.jpg);
  background-color: black;
  background-position-y: 35%;
  height: 600px;
  background-position-x: center;
  background-size: cover;
  font-family: 'Abril Fatface', serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.noteModal {
  position: fixed; /* Stay in place */
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.modalBody {
height: fit-content;
width: 40vw;
padding: 3rem;
background-color: white;
animation-name: animatetop;
animation-duration: 0.4s
}
h3.modalHeader {
  margin-left: auto;
  margin-right: auto;
}
h1.amplify-heading--1 {
  font-size: 10em;
}
div.submission-view {
  border-top: 2px solid black;
}
strong.name {
  border-right: 2px solid black;
}
div.submissions {
  border: 2px solid black;
  border-top: none;
  /* padding-block: .5rem; */
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

@media screen and (max-width: 1000px) {
  .hero {
    background-position-y: 35%;
    height: 400px;
  }
  .modalBody {
    width: 90vw;
    padding: 2rem;
    background-color: white;
    }
  h1.amplify-heading--1 {
    font-size: 5em;
  }
}
